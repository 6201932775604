export const colors = {
  darkGreen: "#0C1D0C",
  mediumGreen: "#264422",
  sapGreen: "#4F772D",
  lightGreen: "#90A955",
  whiteGreen: "#ECF39E",
  hyperlink: "#016fb9",
  design: "#77adff",
  code: "#fdf51f",
};
